import React, { useEffect } from "react";
import { Box, Paper } from "@mui/material";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
/* import Link from "@mui/material/Link"; */
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { matchPath } from "react-router-dom";
import { styled } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import HomeIcon from "@mui/icons-material/Home";
import { useMemo } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import { setReadyToShow } from "../redux/slices/breadCrumbMeterSlice";
import { IwdRoutes } from "../utils/Routes";

import { useIwdBreadCrumb } from "./IwdBreadcrumbProvider";
import zIndex from "@mui/material/styles/zIndex";
import theme from "../theme";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),

  ...theme.mixins.toolbar,
}));
function allValuesPresent(obj) {
  return Object.values(obj).every((value) => {
    return value !== null && value !== undefined && value !== "";
  });
}
const RenderElements = (t, elements, last) => {
  const slicedBreadCrumbs = useSelector(
    (state) => state?.breadCrumbMeterSlice?.breadcrumbs
  );
  const [breadcrumbss, setBreadcrumbs] = useState([]);
  const [currentRoute, setCurrentRoute] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();
  const findMatchingRoute = (path, routes) => {
    for (let route of routes) {
      if (!route.path) {
        continue;
      }

      if (route.path === path) {
        return route.path;
      }

      const match = matchPath(route.path, path);

      if (match) {
        return route.path;
      }
    }

    return null;
  };

  useEffect(() => {
    setCurrentRoute(findMatchingRoute(location.pathname, IwdRoutes));
  }, [location.pathname, breadcrumbss]);

  const replacePathParams = (path, params) => {
    const elems = path?.replace(/:([^/]+)/g, (_, key) => params[key] || key);
    setBreadcrumbs(elems?.split("/")?.map((item) => decodeURIComponent(item)));
  };
  const completeInfo = slicedBreadCrumbs && allValuesPresent(slicedBreadCrumbs);
  useEffect(() => {
    if (completeInfo) {
      replacePathParams(currentRoute, slicedBreadCrumbs);
    }
  }, [currentRoute, slicedBreadCrumbs, completeInfo]);
  const meterShowValue = useSelector(
    (state) => state?.breadCrumbMeterSlice?.identifierTypeValue
  );
  const userName = useSelector((state) => state?.breadCrumbMeterSlice?.user);
  const blockName = useSelector(
    (state) => state?.breadCrumbMeterSlice?.blockName
  );
  const gwSerial = useSelector(
    (state) => state?.breadCrumbMeterSlice?.gatewaySerial
  );

  const overrideBreadcrumbs = (b) => {
    if (b?.length > 2 && b?.[1] == "condominiums") {
      b[2] = blockName;
      return b;
    } else if (b?.length > 2 && b?.[1] == "users") {
      b[2] = userName;
      return b;
    } else {
      return b;
    }
  };
  const escludedStrings = ["apartamentMeter", "apartments", "subcondominiums"];
  const originalBreadcrumbs = elements.split("/");
  const breadcrumbs = elements
    .split("/")
    ?.map((item) => decodeURIComponent(item));

  const overridedBreadcrumbs = overrideBreadcrumbs(breadcrumbs);

  let decodedBreadcrumbs = breadcrumbss?.map((item) => {
    return t(decodeURIComponent(item));
  });

  const lastItem = decodedBreadcrumbs?.pop();

  useEffect(() => {}, [breadcrumbss]);

  const results = decodedBreadcrumbs?.reduce(
    ([base, items], currentValue, currentIndex) => {
      base.push(originalBreadcrumbs[currentIndex]);
      if (originalBreadcrumbs[currentIndex] === "") {
        items.push(
          <IconButton key="home" onClick={() => navigate("/")}>
            <HomeIcon
              sx={{
                fill: theme.palette.header,
              }}
            />
          </IconButton>
        );
      } else {
        const isExcluded = escludedStrings.includes(
          originalBreadcrumbs[currentIndex]
        );
        items.push(
          isExcluded ? (
            <Typography sx={{ color: theme.palette.header }} key={currentIndex}>
              {currentValue}
            </Typography>
          ) : (
            <Link
              style={{
                textDecoration: "none",
                color: theme.palette.header,
                "&:hover": {
                  textDecoration: "underline",
                },
                "&:focus": {
                  outline: "none",
                },
                "&:active": {
                  outline: "none",
                },
              }}
              key={currentIndex}
              underline="hover"
              color={theme.palette.header}
              to={base.join("/")}
            >
              {currentValue}
            </Link>
          )
        );
      }
      return [base, items];
    },
    [[], []]
  );

  if (parseInt(lastItem)) {
    results[1].push(
      <Typography
        key={breadcrumbs.length}
        sx={{
          color: theme.palette.header,
        }}
      >
        {meterShowValue ?? userName ?? blockName ?? gwSerial ?? lastItem}
      </Typography>
    );
  } else {
    results?.[1].push(
      <Typography
        sx={{
          color: theme.palette.header,
        }}
        key={breadcrumbs.length}
      >
        {decodeURIComponent(lastItem)}
      </Typography>
    );
  }

  return results?.[1];
};

const IwdBreadCrumb = () => {
  const { t } = useTranslation();
  const { last } = useIwdBreadCrumb();
  const location = useLocation();
  const meterShowValue = useSelector(
    (state) => state?.breadCrumbMeterSlice?.identifierTypeValue
  );
  const slicedBreadCrumbs = useSelector(
    (state) => state?.breadCrumbMeterSlice?.breadcrumbs
  );
  const userName = useSelector((state) => state?.breadCrumbMeterSlice?.user);
  const results = RenderElements(
    t,
    location.pathname,
    last,
    meterShowValue,
    userName
  );

  useEffect(() => {
    return () => {};
  }, [location]);

  return (
    <Box
      sx={{
        paddingBottom: 0.5,
        mt: 0.3,
        background: theme.palette.headerBackground,
      }}
    >
      {slicedBreadCrumbs && results?.[0]?.props?.children !== "logged" ? (
        <Breadcrumbs
          sx={{ zIndex: 9999999999, color: theme.palette.header }}
          aria-label="breadcrumb"
        >
          {results?.map((r, index) => r)}
        </Breadcrumbs>
      ) : (
        <Box sx={{ paddingBottom: 0.5, height: 40 }}></Box>
      )}
    </Box>
  );
};

export default IwdBreadCrumb;
