import { createTheme } from "@mui/material";

const theme = createTheme({
  typography: {
    fontFamily: "Inter, sans-serif",
    color: window.THEME.typographyColor,
    h1: {
      fontSize: 50,
      fontWeight: 900,
    },
    button: {
      color: "red",
    },
  },
  palette: {
    type: "light",
    primary: {
      main: window?.THEME?.primary,
    },
    secondary: {
      main: window?.THEME?.secondary,
    },
    text: {
      primary: window.THEME.typographyPrimaryColor,
      secondary: window.THEME.typographySecondaryColor,
    },
    version: window.THEME.version,
    footer: window.THEME.footer,
    header: window.THEME.header,
    menu: window.THEME.menu,
    secondaryIcons: window.THEME.secondaryIcons,
    customPagination: window.THEME.customPaginationColor,
    headerBackground: window.THEME.headerBackground,
    navIcon: window.THEME.navIconColor,
    footerBackground: window.THEME.footerBackground,
    blockArrow: window.THEME.blockArrowIconColor,
    formField: window.THEME.muiFormFieldColor,
    chip: window.THEME.widgetChipBackgroundColor,
    chipLabel: window.THEME.widgetChipIconColor,
    error: {
      main: "#bd1e1e",
    },
    warning: {
      main: "#f6ae2d",
    },
    anomaly: {
      main: "#FFFF00",
    },
    orange: {
      main: "#FF5F1F",
    },
    blue: {
      main: "#00bfff",
    },
    messageText: {
      main: "#3c4b64",
    },
    background: {
      paper: "#fff",
    },
    device: {
      main: "#9ABAED",
      contrastText: "#fff",
    },
    group_and_district: {
      main: "#506FA1",
      contrastText: "#fff",
    },
    network: {
      main: "#3c4b64",
      contrastText: "#fff",
    },
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: window?.THEME?.menu,
          color: "#fff",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: window.THEME.buttonText,
          backgroundColor: window?.THEME?.buttonBackGround,
          "&:hover": {
            backgroundColor: window?.THEME?.buttonBackGround,
            color: window?.THEME?.buttonHoverText,
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          fontSize: "12px",
        },
        cell: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: 50,
        },
      },
    },

    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontWeight: 500,
          color: window?.THEME?.muiListItemText,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: window?.THEME?.muiIcon,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: window?.THEME?.tabSelected,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: window?.THEME?.tabSelected,
            fontWeight: 600,
          },
          "&:hover": {
            backgroundColor: window?.THEME?.tabBackground,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& label.Mui-focused": {
            color: window?.THEME?.muiFormFieldColor, // Color of the label text when focused
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: window?.THEME?.muiFormFieldColor, // Color of the bottom border when focused
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: window?.THEME?.muiFormFieldColor, // Default border color
            },
            "&:hover fieldset": {
              borderColor: window?.THEME?.muiFormFieldColor, // Border color on hover
            },
            "&.Mui-focused fieldset": {
              borderColor: window?.THEME?.muiFormFieldColor, // Border color when focused
            },
          },
        },
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      root: {
        color: "#000", // Text color of the select field
      },
      select: {
        "&:hover": {
          backgroundColor: "#000", // Background color on hover
        },
        "&.Mui-focused": {
          backgroundColor: "#000", // Background color when focused
        },
      },
      icon: {
        color: window?.THEME?.muiIcon, // Icon color
      },
      outlined: {
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: "#000", // Border color on hover
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "#000", // Border color when focused
        },
      },
    },
  },
});

export default theme;
