import * as React from "react";
import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { store } from "../../redux/store";
import { setMessage } from "../../redux/slices/snackbarSlice";
import { CircularProgress, Button } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import debounce from "lodash.debounce";
import InputAdornment from "@mui/material/InputAdornment";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
const UserAutocomlete = ({ deviceId }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [getCondominumList] = store.useLazyGetCondominiumListQuery();
  const [getAssociatedUsers] = store.useLazyGetAssociatedUsersQuery();
  const [getUsers] = store.useLazyGetUsersQuery();
  const [associateAdmin, { isSuccess: assocSuccess }] =
    store.useAssociateAdminMutation();
  const [getUserShow, { isSuccess: userSuccess }] =
    store.useLazyGetUserShowQuery();
  const associatedUsers = useSelector(
    (state) => state?.condominiumSlice?.associatedUsers
  );
  const updating = useSelector((state) => state?.widgetSlice?.updating);
  const users = useSelector((state) => state?.userSlice?.users);
  const options = users?.map((item) => ({
    title: `${item?.attributes?.first_name} ${item?.attributes?.last_name} `,
    value: item?.id,
  }));
  const mappedUsers = associatedUsers?.map((item) => ({
    title: `${item?.attributes?.first_name} ${item?.attributes?.last_name} `,
    value: item?.id,
  }));
  const [value, setValue] = useState([]);
  const [firstRender, setFirstRender] = useState(true);
  const [loading, setLoading] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const selectedUsers = value && value?.map((item) => item.value);
  const debValue = debounce((e) => {
    console.log("debik");
    setSearchValue(e.target.value);
  }, 500);
  useEffect(() => {
    if (value && searchValue) {
      setSearchValue("");
      getUsers({ page: 1, per_page: 10 });
    }
  }, [value]);

  useEffect(() => {
    if (!users) {
      getUsers({ page: 1, per_page: 10 });
    }
  }, []);
  useEffect(() => {
    if (mappedUsers?.length > 0 && firstRender) {
      setValue(mappedUsers);
      setFirstRender(false);
    }
  }, [mappedUsers]);
  useEffect(() => {
    if (assocSuccess) {
      dispatch(setMessage(t("assoc_succ")));
      getAssociatedUsers(id);
    }
  }, [assocSuccess]);
  useEffect(() => {
    console.log("condix", users);
  }, [users]);
  useEffect(() => {
    if (searchValue) {
      setLoading(true);
      getUsers({ display_name: searchValue }).then(() => {
        setLoading(false);
      });
    }
  }, [searchValue]);

  useEffect(() => {
    console.log("searchValue", searchValue);
  }, [searchValue]);
  useEffect(() => {
    console.log("valiuxa", value);
  }, [value]);

  const onAssocClick = () => {
    const condIds = value?.map((item) => item.value);
    associateAdmin({
      id: deviceId,
      attributes: {
        realtion_type: "building_administrator",
        user_ids: [...condIds],
      },
    });
  };

  return (
    <>
      <Autocomplete
        multiple
        limitTags={2}
        value={value ?? []}
        id="multiple-limit-tags"
        options={
          options
            ? options?.filter((item) => !selectedUsers?.includes(item.value))
            : []
        }
        getOptionLabel={(option) => option.title}
        onChange={(e, newValue) => {
          setValue(newValue);
        }}
        renderInput={(params) => {
          return (
            <>
              <TextField
                onChange={debValue}
                {...params}
                value={value ?? []}
                /*       defaultValue={mappedUsers ?? []} */
                /*     error={props?.rawErrors?.length > 0}
                required={props.required}
                label={props.schema.title} */
                label={t("search_user")}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <InputAdornment position="end">
                      {loading && (
                        <CircularProgress color="inherit" size={20} />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                onClick={onAssocClick}
                variant={window.BUTTON_TYPE}
                sx={{ mt: 2, mb: 2, float: "right" }}
              >
                {t("assoc")}
              </Button>
            </>
          );
        }}
      />
    </>
  );
};
export default UserAutocomlete;
